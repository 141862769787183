import React, { useEffect} from "react";

import "./SearchResultsPage.scss";

import SearchBar from "../../components/searchbar/Searchbar";
import SearchResults, {
    RenderSpinner,
    SearchResult,
    SearchResultItem
} from "../../components/searchResults/SearchResults";
import {useLocation} from "react-router-dom";
import ReactDOM from "react-dom/client";
import {get} from "../../core/Api";
import AffixfyLogo from "../../components/affixfyLogo/affixfyLogo";

const SearchResultsPage: React.FunctionComponent = (): JSX.Element => {
  const query = useLocation().state.searchQuery;
  const renderSpinner = useLocation().state.renderSpinner;

  useEffect(() => {
      const navigateCallBack = (searchQuery: string) => {
          let placeHolder = document.getElementById('searchResultsWindow')!;
          ReactDOM.createRoot(placeHolder).render(
              <RenderSpinner/>
          );
          try {
              get<{ influencers: SearchResult[] }>("/search", {"query": searchQuery}).catch(error => {
                  ReactDOM.createRoot(placeHolder).render(
                      <div>some thing went wrong, error : {(error as Error).message}</div>
                  );
              }).then((response)=> {
                  if(response){
                      ReactDOM.createRoot(placeHolder).render(
                          <div>
                              {response.influencers.map((searchResult: SearchResult, index: number) => {
                                  return <SearchResultItem key={index} searchResult={searchResult} />;
                              })}
                          </div>
                      )
                  }
              })
          }
          catch (e){
              ReactDOM.createRoot(placeHolder).render(
                  <div>some thing went wrong, error : {(e as Error).message}</div>
              );
          }
      }
      navigateCallBack(query);
  }, [query])

  return (
    <div className="SearchResultsPage">
      <div className="SearchResultsPage--navbar">
        <AffixfyLogo className={"SearchResultsPage--logo"}/>
        <div className="SearchResultsPage--searchBar">
          <SearchBar defaultValue={query}/>
        </div>
      </div>

      <SearchResults searchQuery={query} renderSpinner={renderSpinner}/>
    </div>
  );
};

export default SearchResultsPage;
