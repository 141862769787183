import React from "react";

import "./SearchResults.scss";

export interface SearchResult {
  channel_image: string;
  channel_name: string;
  sub_count: string;
  view_count: string;
  channel_country?: string;
}

export interface SearchResultItemProps {
  searchResult: SearchResult;
}

export const SearchResultItem: React.FunctionComponent<SearchResultItemProps> = ({
  searchResult,
}): JSX.Element => {
  return (
    <div className="SearchResultItem">
      <div className="SearchResultItem--influencerDetails">
        <img
          className="SearchResultItem--profile-picture"
          src={searchResult.channel_image}
          alt="nil"
          width={"50px"}
          height={"50px"}
        />
        <div className="SearchResultItem--influencerContact">
          <p className="SearchResultItem--influencerName">
            {searchResult.channel_name}
          </p>
          <p className="SearchResultItem--influencerEmail">
            <span className="SearchResultItem--influencerData">Subscriber count : {searchResult.sub_count}</span>
            {
              searchResult.channel_country &&
              <span className="SearchResultItem--influencerData">Region: {searchResult.channel_country}</span>
            }
            <span className="SearchResultItem--influencerData">Total views across channel: {searchResult.view_count}</span>
          </p>
        </div>
      </div>

      <div className="SearchResultItem--influencerPrice">
        youtube_button
      </div>
    </div>
  );
};

const SearchResults: React.FunctionComponent<{searchQuery: string, renderSpinner: boolean}> = (params): JSX.Element => {
  return (
    <div className="SearchResults">
      <h5 className="SearchResults--heading">Influencers</h5>
      <div id = "searchResultsWindow">
          {params.renderSpinner ? (<RenderSpinner/>): null}
      </div>
    </div>
  );
};

export const RenderSpinner: React.FunctionComponent = () => {
    return (
        <div className={"loaderContainer"}>
        <span className={"loader"}/>
        </div>
    );
}

export default SearchResults;
