const AFFIXFY_APP_URI= process.env.REACT_APP_API_ENDPOINT;

export const get = async <Type>(route: string, params: { [key : string] : string }): Promise<Type> => {
    let query = Object.keys(params).map((key: string) => key + '=' + params[key]).join('&');
    let url = `${AFFIXFY_APP_URI}${route}?${query}`;
    let response = await fetch(url);
    if(response.ok){
        return await response.json();
    }
    throw new Error(`Failed HTTP GET call to ${route}, call returned ${response.status}`);
}