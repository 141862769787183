export interface CreateApiHelperResponse {
  getApiData: () => Promise<any>;
}

export const createApiHelper = (endpoint: string): CreateApiHelperResponse => {
  const getApiData = async () => {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error("Failed to fetch API data");
    }

    return response.json();
  };

  return { getApiData };
};
