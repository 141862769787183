import { BrowserRouter, Route, Routes } from "react-router-dom";

import LandingPage from "./Pages/landingPage/LandingPage";
import SearchResultsPage from "./Pages/searchResultsPage/SearchResultsPage";
import { ApiProvider } from "./contexts/ApiContext";
import { createApiHelper } from "./utils/api-util";

const index = () => {
  const endpoint = process.env.REACT_APP_API_ENDPOINT;
  if (!endpoint) {
    throw new Error("API endpoint not found");
  }
  const apiHelper = createApiHelper(endpoint);

  return (
    <ApiProvider value={apiHelper as { getApiData: () => Promise<any> }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={LandingPage} />
          <Route path="search" Component={SearchResultsPage} />
          <Route path="*" Component={LandingPage} />
        </Routes>
      </BrowserRouter>
    </ApiProvider>
  );
};

export default index;
