import React from "react";
import {useNavigate} from "react-router-dom";

const AffixfyLogo: React.FunctionComponent<{className: string}> = (props) => {
    const navigate = useNavigate();
    const onClickFunction = React.useCallback(() => {
        navigate('/');
    }, [navigate])
    return (
        <img
            src="affixfy_logo.png"
            className={props.className}
            alt="Affixfy Logo"
            onClick={onClickFunction}
        ></img>
    );
}

export default AffixfyLogo;