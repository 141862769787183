import React from "react";

import "./LandingPage.scss";
import SearchBar from "../../components/searchbar/Searchbar";
import AffixfyLogo from "../../components/affixfyLogo/affixfyLogo";

const LandingPage: React.FunctionComponent = (): JSX.Element => {

  return (
    <div className="LandingPage">
      <div className="LandingPage--container">
        <AffixfyLogo className={"LandingPage--logo"}/>
        <div className="LandingPage--SearchBar">
          <SearchBar />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
