import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./SearchBar.scss";
import {useNavigate} from "react-router-dom";


const SearchBar: React.FunctionComponent<{
  error?: boolean;
  defaultValue?: string;
}> = ({ error, defaultValue }): JSX.Element => {

    const navigate= useNavigate();

    const searchFunction = React.useCallback(async (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let input = document.getElementById("default-search")! as HTMLInputElement;
        if((event.type === 'click' && event.currentTarget.id === 'searchButton') || (event.nativeEvent instanceof KeyboardEvent && event.nativeEvent.key === 'Enter')){
            navigate('/search', {state: {searchQuery: input.value, renderSpinner: true }});
        }
    }, [navigate]);

    return (
    <div className="SearchBar">
      <div className="SearchBar--iconContainer">
        <FontAwesomeIcon icon={faSearch} color="gray" />
      </div>
      <input
        type="search"
        id="default-search"
        className={`SearchBar--input ${error ? "SearchBar--input-error" : ""}`}
        placeholder="Search filter"
        defaultValue={defaultValue}
        onKeyDown={searchFunction}
      />
      <button
        id = "searchButton"
        type="submit"
        className="SearchBar--button"
        onClick={searchFunction}
      >
        Search
      </button>
    </div>
  );
};

export default SearchBar;
